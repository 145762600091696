import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/App";
import "normalize.css/normalize.css";
import { HotkeysProvider } from "@blueprintjs/core";
import { BrowserRouter } from 'react-router-dom';
import { IResizeEntry, ResizeSensor } from "@blueprintjs/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function handleResize(entries: IResizeEntry[]) {
  let bodyEntry = entries.find(e => e.target.tagName === "BODY");
  if (bodyEntry) {
    window.parent.postMessage({
      type: "dataExplorerContentResize",
      height: bodyEntry.contentRect.height + 50
    }, process.env.REACT_APP_TARGET_ORIGIN!);
  }
}

root.render(
  <ResizeSensor onResize={handleResize} observeParents={true}>
    <HotkeysProvider>
      <BrowserRouter>
        <App></App>
      </BrowserRouter>
    </HotkeysProvider>
  </ResizeSensor>
);
