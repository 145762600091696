import { Callout } from "@blueprintjs/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

function OauthRedirect() {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            const projectCode = window.sessionStorage.getItem("projectCode") || "";
            window.sessionStorage.removeItem("projectCode");

            navigate({
                pathname: '/explorer',
                search: `?projectCode=${projectCode}`
            }, { replace: true });
        }
    });

    return <Callout>Loading...</Callout>
}

export default OauthRedirect;