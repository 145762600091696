import { Button, Callout, Divider, Intent } from "@blueprintjs/core";
import CodeMirror from "@uiw/react-codemirror";
import { StreamLanguage } from "@codemirror/language";
import { EditorView } from "@codemirror/view";
import { python } from "@codemirror/legacy-modes/mode/python"
import { properties } from "@codemirror/legacy-modes/mode/properties"
import { normalizeProjectCode } from "../helpers";
import { useState } from "react";
import { AwsToken } from "../contexts/awsTokenContext";
import { useAuth } from "../hooks/useAuth";
import "./ExternalConnectionPanel.scss";

interface ExternalConnectionPanelProps {
    projectCode: string
}

function ExternalConnectionPanel(props: ExternalConnectionPanelProps) {
    const auth = useAuth();
    const [awsToken, setAwsToken] = useState<AwsToken>()
    const [awsTokenState, setAwsTokenState] = useState<"initial" | "loading" | "error" | "success">("initial");

    const { s3Name, databaseName } = normalizeProjectCode(props.projectCode);
    const catalogName = 'AwsDataCatalog'
    const schema = `${process.env.REACT_APP_DATABASE_PREFIX}_${databaseName}_${process.env.REACT_APP_DATABASE_SUFFIX}`
    const workgroup = `${process.env.REACT_APP_ATHENA_WORKGROUP}`
    const s3OutputLocation = `${process.env.REACT_APP_S3_PREFIX}/${s3Name}`
    const awsRegion = 'us-east-1';

    let awsCredentials = "";

    const odbcConnectionString = `Driver=Simba Athena ODBC Driver;AwsRegion=${awsRegion};Workgroup=${workgroup};Catalog=${catalogName};Schema=${schema};S3OutputLocation=${s3OutputLocation};AuthenticationType=IAM Profile;AWSProfile=${databaseName};`

    const pythonCode = `import pandas as pd
import pyodbc as odbc

con = odbc.connect("DSN=${databaseName}")
df = pd.read_sql('select * from "allparticipants" limit 10', con)

print(df.head())`;

    const AwsCredentials = () => {
        awsCredentials = `[${databaseName}]
aws_access_key_id = ${awsToken?.AccessKeyId}
aws_secret_access_key = ${awsToken?.SecretAccessKey}
aws_session_token = ${awsToken?.SessionToken}`;

        return (
            <div>
                <div className="file-container">
                    <CodeMirror
                        id="aws-credentials"
                        value={awsCredentials}
                        height="auto"
                        theme="dark"
                        extensions={[StreamLanguage.define(properties), EditorView.lineWrapping]}
                        editable={false}
                        basicSetup={{
                            lineNumbers: false
                        }}
                    ></CodeMirror>
                    <Button
                        className="file-copy-button copy-button"
                        icon="clipboard"
                        title="Copy AWS Credentials"
                        text="Copy"
                        intent={Intent.PRIMARY}
                        onClick={() => navigator.clipboard.writeText(awsCredentials)} />
                </div>
                <p className='expiration'>
                    <span className="label"> Token expires at:</span> <span className="value bp4-monospace-text">{new Date(awsToken?.Expiration!).toLocaleString(undefined, { timeZoneName: 'short' })}</span>
                </p>
            </div>
        )
    }

    const AwsCredentialsLoading = () => {
        return (
            <div className="file-container">
                <CodeMirror
                    id="aws-credentials"
                    value="Loading..."
                    height="auto"
                    theme="dark"
                    extensions={[StreamLanguage.define(properties), EditorView.lineWrapping]}
                    editable={false}
                    basicSetup={{
                        lineNumbers: false
                    }}
                ></CodeMirror>
                <Button
                    className="file-copy-button copy-button"
                    icon="clipboard"
                    title="Copy AWS Credentials"
                    text="Copy"
                    intent={Intent.PRIMARY}
                    onClick={() => navigator.clipboard.writeText(awsCredentials)} />
            </div>
        )
    }

    async function requestToken(): Promise<AwsToken> {
        const result = await fetch(process.env.REACT_APP_AWS_GET_SESSION_URL!, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `${auth.user?.access_token}`,
                "ProjectCode": props.projectCode
            },
            body: JSON.stringify({
                "extendedDuration": true
            })
        });

        if (result.ok) {
            return await result.json() as AwsToken;
        }

        throw new Error("Failed to get AWS token");

    }

    async function generateCredentials() {
        setAwsTokenState("loading");
        try {
            const token = await requestToken();
            setAwsToken(token);
            setAwsTokenState("success");
        } catch {
            setAwsTokenState("error");
        }
    }

    return (
        <div>
            <section>
                <p>
                    It is possible to connect a data analysis application such as PowerBI, Tableau, RStudio, or Jupyter Notebooks directly to your project's export database.
                    You can use ODBC, JDBC, or Amazon Athena libraries.
                </p>
                <Divider />

                <h3>Connection Information</h3>
                <p>
                    Use the following information to connect to your project's export database from ODBC or JDBC.
                </p>
                <br />
                <table>
                    <tbody>
                        <tr>
                            <td className="label">AWS Region</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy Aws Region"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(awsRegion)}
                                />
                            </td>
                            <td className="value bp4-monospace-text">{awsRegion}</td>
                        </tr>
                        <tr>
                            <td className="label">Catalog</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy Catalog"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(catalogName)} />
                            </td>
                            <td className="value bp4-monospace-text">{catalogName}</td>
                        </tr>
                        <tr>
                            <td className="label">Schema</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy Schema"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(schema)} />
                            </td>
                            <td className="value bp4-monospace-text">{schema}</td>
                        </tr>
                        <tr>
                            <td className="label">Workgroup</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy Workgroup"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(workgroup)} />
                            </td>
                            <td className="value bp4-monospace-text">{workgroup}</td>
                        </tr>
                        <tr>
                            <td className="label">S3 Output Location</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy S3 Output Location "
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(s3OutputLocation)} />
                            </td>
                            <td className="value bp4-monospace-text">{s3OutputLocation}</td>
                        </tr>
                    </tbody>
                </table>

                <Divider />
            </section>

            <section>
                <h3>Authentication</h3>
                <p>
                    In order to connect to your database, you will need to create an <a href="https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-files.html" target="_blank" rel="noopener noreferrer">AWS credentials file</a> on your machine.
                    For security reasons, these are temporary credentials that will remain valid for only 12 hours.
                    To use them with your application, create a file named <code>~/.aws/credentials</code> (where <code>~</code> is your home directory).
                    Use the button below to create the credentials, then copy them into your credentials file. After your credentials expire, you can come back to this page, regenerate new credentials, and
                    paste them into the same file, replacing the old ones.
                </p>
                <Button
                    intent={Intent.PRIMARY}
                    title="Create Credentials"
                    text="Create Credentials"
                    disabled={awsTokenState !== "initial"}
                    onClick={() => generateCredentials()}>
                </Button>
                <p />
                {awsTokenState === "loading" ? <AwsCredentialsLoading /> : null}
                {awsTokenState === "success" ? <AwsCredentials /> : null}
                {awsTokenState === "error" ? <Callout intent={Intent.WARNING}>An error ocurred when creating credentials.</Callout> : null}

                <Divider />
            </section>

            <section>
                <h3>ODBC Configuration</h3>
                <p>
                    Find detailed instructions for configuring ODBC on your operating system <a href="https://docs.aws.amazon.com/athena/latest/ug/connect-with-odbc.html" target="_blank" rel="noopener noreferrer">here</a>.
                    At a high level, you will need to:
                </p>
                <ul>
                    <li>Download the <a href="https://docs.aws.amazon.com/athena/latest/ug/connect-with-odbc.html" target="_blank" rel="noopener noreferrer"> ODBC driver</a> for your system</li>
                    <li>Add a new DSN  with name {databaseName}</li>
                    <li>Select the <code>Simba Athena ODBC driver</code></li>
                    <li>Configure the DSN with the above connection information.</li>
                    <li>Set the Authentication Type to "IAM Profile". The AWS Profile should be <span className="bp4-monospace-text">{databaseName}</span></li>
                </ul>

                <p>Alternatively, some application may require the use of an ODBC connection string instead of a DSN. In that case,
                    you'll need to install the ODBC driver for your system, then use the following connection string</p>
                <div className="file-container">
                    <CodeMirror
                        id="odbc-connection-string"
                        value={odbcConnectionString}
                        height="auto"
                        theme="dark"
                        basicSetup={{
                            lineNumbers: false
                        }}
                        extensions={[EditorView.lineWrapping]}
                    ></CodeMirror>

                    <Button
                        className="file-copy-button copy-button"
                        icon="clipboard"
                        title="Copy ODBC Connection String"
                        text="Copy"
                        intent={Intent.PRIMARY}
                        onClick={() => navigator.clipboard.writeText(odbcConnectionString)} />
                </div>
                <Divider />
            </section>

            <section>
                <h3>JDBC Configuration</h3>
                <p>
                    Find detailed instructions for configuring JDBC on your operating system <a href="https://docs.aws.amazon.com/athena/latest/ug/connect-with-jdbc.html" target="_blank" rel="noopener noreferrer">here</a>.
                    In addition to the connection information settings above, the following settings will be needed when configuring your JDBC connection:
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td className="label">AwsCredentialsProviderClass</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy AwsCredentialsProviderClass"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText("com.simba.athena.amazonaws.auth.profile.ProfileCredentialsProvider")}
                                />
                            </td>
                            <td className="value bp4-monospace-text">com.simba.athena.amazonaws.auth.profile.ProfileCredentialsProvider</td>
                        </tr>
                        <tr>
                            <td className="label">AwsCredentialsProviderArguments</td>
                            <td >
                                <Button
                                    className="copy-button"
                                    icon="clipboard"
                                    title="Copy AwsCredentialsProviderArguments"
                                    minimal={true}
                                    onClick={() => navigator.clipboard.writeText(databaseName)}
                                />
                            </td>
                            <td className="value bp4-monospace-text">{databaseName}</td>
                        </tr>
                    </tbody>
                </table>
                <Divider />
            </section>

            <section>
                <h3>Python</h3>
                <p>
                    There are several ways to connect to your database using Python. One is to use an AWS package such
                    as < a href="https://boto3.amazonaws.com/v1/documentation/api/latest/index.html" target="_blank" rel="noopener noreferrer">boto3</a> or <a href="https://pypi.org/project/pyathena/" target="_blank" rel="noopener noreferrer">pyAthena</a>.
                    You can also use an odbc package such as <a href="https://pypi.org/project/pyodbc/" target="_blank" rel="noopener noreferrer">pyodbc</a>.  The following example uses pyodbc and assumes you have already
                    set up an ODBC DSN with the name {databaseName} on your system.
                </p>
                <br />
                <div className="file-container">
                    <CodeMirror
                        id="python"
                        value={pythonCode}
                        height="auto"
                        theme="dark"
                        extensions={[StreamLanguage.define(python)]}
                    ></CodeMirror>

                    <Button
                        className="file-copy-button copy-button"
                        icon="clipboard"
                        title="Copy Python Sample"
                        text="Copy"
                        intent={Intent.PRIMARY}
                        onClick={() => navigator.clipboard.writeText(pythonCode)} />
                </div>
                <Divider />
            </section>
        </div>
    )
}

export default ExternalConnectionPanel;