import { Tab, Tabs } from "@blueprintjs/core";
import QueryPanel from "./QueryPanel";
import ExternalConnectionPanel from './ExternalConnectionPanel';
import './MainPanel.scss';

interface MainPanelProps {
    projectCode: string
}

function MainPanel(props: MainPanelProps) {
    return (
        <div className="main-container">
            <Tabs id="main-panel" large={true}>
                <Tab id="query" title="Query" panel={<QueryPanel projectCode={props.projectCode} />} />
                <Tab id="external" title="External Connection" panel={<ExternalConnectionPanel projectCode={props.projectCode} />} />
            </Tabs>
        </div>
    )
}

export default MainPanel;