import React from "react";

export interface AwsToken {
    AccessKeyId: string,
    SecretAccessKey: string,
    SessionToken: string
    Expiration: string
}

export type AwsAuthState = "initial" | "loading" | "success" | "error" | "refreshing";

export interface AwsTokenContextProps {
    awsToken?: AwsToken;
    awsAuthState: AwsAuthState;
}

export const AwsTokenContext = React.createContext<AwsTokenContextProps | undefined>(undefined);
AwsTokenContext.displayName = "AwsTokenContext";