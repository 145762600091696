import { Callout } from "@blueprintjs/core";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

function OauthLogin() {
    const auth = useAuth();
    const [params] = useSearchParams();

    setTimeout(() => {
        const projectCode = params.get("projectCode");
        window.sessionStorage.setItem("projectCode", projectCode || "");
        auth.signinRedirect();
    }, 100);
    return (
        <Callout>Loading...</Callout>
    )
}

export default OauthLogin;