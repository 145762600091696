import React, { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { ViewUpdate } from '@codemirror/view';
import { sql } from "@codemirror/lang-sql";
import { Button, Intent } from "@blueprintjs/core";
import "./QueryEditor.scss";
import { GlueDatabaseSchema } from "../hooks/useGlue";

interface QueryEditorProps {
  startQuery: (query: string) => Promise<any>;
  projectCode: string;
  schema: GlueDatabaseSchema;
  onEditorUpdate: (viewUpdate: ViewUpdate | undefined) => void;
}

type CodeMirrorTableSchema = { [table: string]: readonly string[] };

const QueryEditor = (props: QueryEditorProps) => {

  const [query, setQuery] = useState<string>(`select * from "allparticipants" limit 10`);

  const codeMirrorSchema: CodeMirrorTableSchema = {};
  if (props.schema && Object.keys(props.schema).length > 0) {
    Object.keys(props.schema).forEach(tableName => {
      codeMirrorSchema[tableName] = props.schema[tableName].map(c => c.name);
    });
  }

  const handleQuery = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    props.startQuery(query);
  };

  return (
    <div>
      <CodeMirror
        id="query"
        value={query}
        extensions={[sql({ schema: codeMirrorSchema })]}
        onChange={setQuery}
        onUpdate={props.onEditorUpdate}
        height={"400px"}
        theme={"dark"}
      />
      <Button onClick={handleQuery} intent={Intent.PRIMARY} fill>
        Run
      </Button>
    </div>
  );
};

export default QueryEditor;
