import {
  AthenaResultData,
  AthenaResultDataHeader,
  AthenaResultDataRow,
} from "../hooks/useAthena";
import { NonIdealState } from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import "./QueryResultsTable.scss";
interface QueryResultsTableProps {
  queryResultsData?: AthenaResultData;
}

const QueryResultsTable = ({ queryResultsData }: QueryResultsTableProps) => {
  let headers: AthenaResultDataHeader[] = [];
  let data: AthenaResultDataRow[] = [];

  if (
    !queryResultsData?.headers ||
    queryResultsData.headers.length === 0 ||
    !queryResultsData?.rows ||
    queryResultsData.rows.length === 0
  ) {
  } else {
    headers = queryResultsData.headers;
    data = queryResultsData.rows;
  }

  if (data.length === 0) {
    return (
      <div className="query-results-none">
        <NonIdealState title="No query results" icon="search" />
      </div>
    );
  }

  return (
    <div className="query-results-table-container">
      <Table2 numRows={queryResultsData?.rows.length}>
        {headers.map(({ name, label }, columnIndex) => (
          <Column
            key={name}
            id={name}
            name={label}
            cellRenderer={(rowIndex: number) => (
              <Cell>{data[rowIndex].values[columnIndex] || ""}</Cell>
            )}
          />
        ))}
      </Table2>
    </div>
  );
};

export default QueryResultsTable;
