import { QueryExecutionState } from "@aws-sdk/client-athena";
import { NonIdealState } from "@blueprintjs/core";
import { AthenaQueryResults } from "../hooks/useAthena";
import QueryResultsTable from "./QueryResultsTable";
import { Callout, Intent, AnchorButton, Classes } from "@blueprintjs/core";
import { useS3 } from "../hooks/useS3";
import { DateTime } from 'luxon';
import "./QueryResults.scss";

interface QueryResultsProps {
  queryResults?: AthenaQueryResults;
}

const getQueryStateIntent = (state: string | undefined) => {
  switch (state) {
    case QueryExecutionState.CANCELLED:
      return Intent.WARNING;

    case QueryExecutionState.FAILED:
      return Intent.DANGER;

    case QueryExecutionState.QUEUED:
      return Intent.NONE;

    case QueryExecutionState.RUNNING:
      return Intent.NONE;

    case QueryExecutionState.SUCCEEDED:
      return Intent.SUCCESS;

    default:
      return Intent.NONE;
  }
};


const shouldShowDownloadButton = (queryExecutionState: string | undefined): boolean => {
  switch (queryExecutionState) {
    case QueryExecutionState.SUCCEEDED: return true;
    case QueryExecutionState.CANCELLED: return true;
    case QueryExecutionState.FAILED: return false;
    case QueryExecutionState.QUEUED: return true;
    case QueryExecutionState.RUNNING: return true;
    default: return true;
  }
}

const QueryResults = (props: QueryResultsProps) => {
  const queryState: string | undefined = props.queryResults?.status?.State;
  const resultsUrl = useS3(props.queryResults?.projectCode!, props.queryResults?.queryExecutionId);
  let submissionTimeString = "";
  let duration = 0;
  if (props.queryResults?.status?.SubmissionDateTime) {
    const submissionTime = DateTime.fromJSDate(props.queryResults.status.SubmissionDateTime)
    submissionTimeString = submissionTime.toLocaleString(DateTime.TIME_WITH_SECONDS);
    let completionTime = submissionTime;
    if (props.queryResults.status.CompletionDateTime) {
      completionTime = DateTime.fromJSDate(props.queryResults.status.CompletionDateTime);
    }
    duration = completionTime.diff(submissionTime).as("seconds");
  }
  return (
    <>
      <div className="query-results-container">
        <Callout
          intent={getQueryStateIntent(queryState)}
          icon={getQueryStateIntent(queryState) === Intent.NONE ? "search" : undefined}
          title={queryState ? `Query ${queryState?.toLocaleLowerCase()}` : "Query Status"}>

          <div className="status-element-container">
            {submissionTimeString &&
              <>
                <div className="status-element">
                  <div className="status-element-label">Submission Time:</div>
                  <div className="status-element-value">{submissionTimeString}</div>
                </div>

                <div className="status-element">
                  <div className="status-element-label">Duration:</div>
                  <div className="status-element-value">{duration} seconds</div>
                </div>
              </>
            }
            {props.queryResults?.status?.AthenaError &&
              <div className={Classes.MONOSPACE_TEXT + " error-message"}>
                {props.queryResults.status.AthenaError.ErrorMessage}
              </div>
            }
          </div>

        </Callout>

        {shouldShowDownloadButton(queryState) &&
          <Callout intent={getQueryStateIntent(queryState)} icon={false} className="download-button-container">
            <AnchorButton
              href={resultsUrl}
              text="Download Results"
              icon="cloud-download"
              disabled={queryState !== QueryExecutionState.SUCCEEDED} />
          </Callout>
        }
      </div>

      {!props.queryResults?.queryExecutionId &&
        <NonIdealState title="No query results" icon="search" />
      }

      {queryState === QueryExecutionState.SUCCEEDED && props.queryResults &&
        <QueryResultsTable queryResultsData={props.queryResults.data} />
      }
    </>
  );
};

export default QueryResults;
