import React from "react";

import { AwsTokenContext, AwsTokenContextProps } from "../contexts/awsTokenContext";

export const useAwsToken = (): AwsTokenContextProps => {
  const context = React.useContext(AwsTokenContext);

  if (!context) {
    throw new Error("AwsTokenProvider context is undefined, please verify you are calling useAws() as child of a <AwsTokenProvider> component.");
  }

  return context;
};