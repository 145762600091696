
import React, { useEffect, useState } from "react";
import { AwsTokenContext, AwsToken, AwsAuthState } from "../contexts/awsTokenContext";
import { useAuth } from "../hooks/useAuth";

export interface AwsTokenProviderProps {
    children?: React.ReactNode;
    projectCode: string;
}

export const AwsTokenProvider = (props: AwsTokenProviderProps): JSX.Element => {
    const { children } = props;
    const auth = useAuth();
    const [awsToken, updateAwsToken] = useState<AwsToken | undefined>(undefined);
    const [awsAuthState, updateAwsTokenStatus] = useState<AwsAuthState>("initial");
    const [checkRefreshInterval, setCheckRefreshInterval] = useState<NodeJS.Timer | undefined>(undefined);

    async function requestToken() {
        try {
            const result = await fetch(process.env.REACT_APP_AWS_GET_SESSION_URL!, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": `${auth.user?.access_token}`,
                    "ProjectCode": props.projectCode
                },
            });

            if (result.ok) {
                let newToken = await result.json() as AwsToken;
                updateAwsToken(newToken);
                updateAwsTokenStatus("success");
            } else {
                updateAwsTokenStatus("error");
            }

        } catch (e) {
            updateAwsTokenStatus("error");
        }
    }

    useEffect(() => {
        if (auth.isAuthenticated && awsAuthState === "initial") {
            updateAwsTokenStatus("loading");
            void (async () => await requestToken())();
            return;
        } else if (!auth.isAuthenticated) {
            updateAwsTokenStatus("initial");
            updateAwsToken(undefined);
        }

    }, [auth.isAuthenticated, awsAuthState]);

    //refreshes the aws token. The interval is set in two differet cases:
    //1. When the awsAuthState transitions to success (meaning we just received a new aws token)
    //2. When we get a new MDHD access token. In this case the old interval needs to be cleared and a new one started to encapsulate the new MDHD access token.
    useEffect(() => {
        if (checkRefreshInterval) {
            clearInterval(checkRefreshInterval);
        }
        if (awsAuthState === "success") {
            const interval = setInterval(() => {
                const inTwoMinutes = new Date(new Date().getTime() + 2 * 60 * 1000);
                if (new Date(awsToken!.Expiration) < inTwoMinutes) {
                    updateAwsTokenStatus("refreshing");
                    void (async () => await requestToken())();
                }
            }, 5000);
            setCheckRefreshInterval(interval);
        }
    }, [awsAuthState, auth.user?.access_token]);

    return (
        <AwsTokenContext.Provider value={{ awsToken, awsAuthState }}>
            {children}
        </AwsTokenContext.Provider>
    )
}