import { AnchorButton, Divider, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { useState, MouseEvent, useEffect } from "react";
import { GlueDatabaseSchema } from "../hooks/useGlue";
import "./SchemaTree.scss";

interface SchemaTreeProps {
  projectCode: string;
  schema: GlueDatabaseSchema;
  onNodeClick: (nodeData: string) => void;
}

function getInitialSchemaNodes(schema: GlueDatabaseSchema): ReadonlyArray<TreeNodeInfo<string>> {
  const schemaNodes: TreeNodeInfo<string>[] = [];
  Object.keys(schema).forEach(tableName => {
    const tableNode = {
      id: tableName,
      label: (
        <div className="table-name" title={`Copy  "${tableName}" to editor`}>{tableName}</div>
      ),
      nodeData: tableName,
      depth: 0,
      isExpanded: false,
      childNodes: schema[tableName].map(column => ({
        id: column.name,
        nodeData: column.name,
        depth: 1,
        label: (
          <div className="column-name" title={`Copy  "${column.name}" to editor`}>{column.name}</div>
        ),
        secondaryLabel: column.type,
      }) as TreeNodeInfo<string>),
    }
    schemaNodes.push(tableNode);
  });
  return schemaNodes as ReadonlyArray<TreeNodeInfo<string>>;
}

const SchemaTree = (props: SchemaTreeProps) => {
  const [schemaNodes, setSchemaNodes] = useState<ReadonlyArray<TreeNodeInfo<string>> | undefined>(undefined);

  useEffect(() => {
    if (props.schema && Object.keys(props.schema).length > 0) {
      setSchemaNodes(getInitialSchemaNodes(props.schema));
    }
  }, [props.schema]);

  function switchExpansion(node: TreeNodeInfo, nodePath: number[], e: MouseEvent<HTMLElement>) {
    if (!node.childNodes) {
      return;
    }

    const newNodes = schemaNodes?.map(n => {
      if (n.id === node.id) {
        n.isExpanded = !n.isExpanded;
      }
      return n;
    })

    setSchemaNodes(newNodes);
  }

  function copyToEditor(node: TreeNodeInfo<string>, nodePath: number[], e: MouseEvent<HTMLElement>) {
    if (!node || !node.nodeData) {
      return;
    }
    props.onNodeClick(node.nodeData);
  }

  if (schemaNodes === undefined) {
    return <div></div>;
  }


  return (
    <div className="schema-tree-container">
      <h3 className="tree-title">Database Schema</h3>
      <AnchorButton
        className="help"
        title="Go to documentation"
        href="https://support.mydatahelps.org/hc/en-us/articles/8160998106259-Export-Explorer"
        icon="help"
        target="_blank"
        small={true}
        minimal={true} />
      <Divider />
      <Tree
        onNodeExpand={switchExpansion}
        onNodeCollapse={switchExpansion}
        onNodeClick={copyToEditor}
        contents={schemaNodes}
      ></Tree>
    </div>
  );
}


export default SchemaTree;
