import { ViewUpdate } from '@codemirror/view';
import { useRef } from "react";

export interface UseCodeMirror {
    setViewUpdate: (newViewUpdate: ViewUpdate | undefined) => void;
    setNameAtCursor: (schemaIdentifier: string) => void;
}

export const useCodeMirror = (): UseCodeMirror => {
    const viewUpdate = useRef<ViewUpdate>();

    const setNameAtCursor = (token: string) => {
        const updateTxn = viewUpdate.current?.state.replaceSelection(token);
        if (updateTxn) {
            viewUpdate?.current?.view.dispatch(updateTxn);
        }
    }

    const setViewUpdate = (newViewUpdate: ViewUpdate | undefined) => {
        viewUpdate.current = newViewUpdate;
    }

    return {
        setViewUpdate,
        setNameAtCursor
    }
}