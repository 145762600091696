// src/App.jsx
import { Callout, Intent } from "@blueprintjs/core";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { AuthProvider } from "../providers/AuthProvider";
import { AwsTokenProvider } from "../providers/AwsTokenProvider";
import "./App.scss";
import MainPanel from "./MainPanel";
import OauthLogin from "./OauthLogin";
import OauthRedirect from "./OauthRedirect";
import ProtectedRoute from "./ProtectedRoute";

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY!,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
  redirect_uri: `https://${window.location.hostname!}/oauth_redirect`,
  response_type: "code",
  scope: "openid roles api permissions exportexplorer",
};

function ProjectMainPanel() {
  const [params] = useSearchParams();
  const projectCode = params.get("projectCode");
  if (projectCode) {
    return (
      <AwsTokenProvider projectCode={projectCode}>
        <MainPanel projectCode={projectCode} ></MainPanel>
      </AwsTokenProvider>
    )
  } else {
    return <Callout intent={Intent.WARNING}>No project code specified</Callout>
  }
}

function InvalidPath() {
  return <Callout intent={Intent.WARNING}>Resource not found</Callout>
}

function App() {
  return (
    <AuthProvider {...oidcConfig} >
      <Routes>
        <Route path="/login" element={<OauthLogin />} />
        <Route path="/oauth_redirect" element={<OauthRedirect />} />
        <Route path="/explorer" element={
          <ProtectedRoute>
            <ProjectMainPanel />
          </ProtectedRoute>
        } />
        <Route path="*" element={<InvalidPath />} />
      </Routes>
    </AuthProvider>
  )
}

export default App;
