import { Callout, Intent } from "@blueprintjs/core";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

function ProtectedRoute({ children }: { children: JSX.Element }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const location  = useLocation();

    useEffect(() => {
        if (auth.isInitialized && !auth.isAuthenticated) {
            navigate({
                pathname: '/login',
                search: location.search,
            }, { replace: true });
        }
    })

    if (!auth.isInitialized) {
        return (
            <Callout>Loading...</Callout>
        )
    }

    if (auth.error) {
        return (
            <Callout intent={Intent.DANGER}>Oops... {auth.error.message}</Callout>
        );
    }

    if (!auth.isAuthenticated) {
        return (
            <Callout>Loading...</Callout>
        )
    }

    return children;
}

export default ProtectedRoute;