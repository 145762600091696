import { GetTablesCommand, GlueClient } from "@aws-sdk/client-glue";
import { useEffect, useState } from "react";
import { AwsToken } from "../contexts/awsTokenContext";
import { normalizeProjectCode } from "../helpers";
import { useAwsToken } from "./useAwsToken";

export interface UseGlue {
    schema: GlueDatabaseSchema,
    schemaLoadingStatus: SchemaLoadingStatus
}

export type SchemaLoadingStatus = "loading" | "success" | "error" | "initial"

export interface GlueDatabaseSchema {
    [table: string]: GlueDatabaseColumn[]
}

export interface GlueDatabaseColumn {
    name: string;
    type: string;
}

const createClient = (awsToken: AwsToken) => {
    return new GlueClient({
        region: process.env.REACT_APP_AWS_REGION!,
        credentials: {
            accessKeyId: awsToken.AccessKeyId,
            secretAccessKey: awsToken.SecretAccessKey,
            sessionToken: awsToken.SessionToken,
            expiration: new Date(awsToken.Expiration)
        }
    });
}

export const useGlue = (projectCode: string): UseGlue => {
    const { awsAuthState, awsToken } = useAwsToken();
    const [schema, setSchema] = useState<GlueDatabaseSchema>({});
    const [schemaLoadingStatus, setSchemaLoadingStatus] = useState<SchemaLoadingStatus>("initial");

    useEffect(() => {
        if (awsAuthState === "success" && awsToken && schemaLoadingStatus === "initial") {
            const glueClient = createClient(awsToken);
            void (async () => await loadSchema(glueClient))();
        }
    }, [awsAuthState, awsToken, schemaLoadingStatus]);


    const loadSchema = async (glueClient: GlueClient) => {
        try {
            setSchemaLoadingStatus("loading");
            await fetchSchema(glueClient);
            setSchemaLoadingStatus("success");
        } catch (e) {
            setSchemaLoadingStatus("error");
        }
    }

    const fetchSchema = async (glueClient: GlueClient) => {
        if (!glueClient) {
            throw new Error("Glue client is not initialized");
        }

        const { databaseName } = normalizeProjectCode(projectCode);

        const command = new GetTablesCommand({
            DatabaseName: `${process.env.REACT_APP_DATABASE_PREFIX}_${databaseName}_${process.env.REACT_APP_DATABASE_SUFFIX}`,
        });
        const tables = await glueClient.send(command);

        let schema: GlueDatabaseSchema = {}

        tables?.TableList?.forEach(table => {
            if (table.Name) {
                const tableName = table.Name;
                schema[tableName] = []
                table.StorageDescriptor?.Columns?.forEach(column => {
                    if (column.Name) {
                        schema[tableName].push({
                            name: column.Name,
                            type: column.Type || "string"
                        })
                    }
                }
                )
            }

        });
        setSchema(schema);
    }

    return {
        schema,
        schemaLoadingStatus
    }
}