import React from "react";
import type { UserManagerSettings, UserManagerEvents, SigninRedirectArgs } from "oidc-client-ts";

import type { AuthState } from "../authentication/authState";

export interface AuthContextProps extends AuthState {
    readonly settings: UserManagerSettings;
    readonly events: UserManagerEvents;
    signinRedirect(args?: SigninRedirectArgs): Promise<void>;
}


export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);
AuthContext.displayName = "AuthContext";