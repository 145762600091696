import { Callout, Divider, Intent } from "@blueprintjs/core";
import { useAthena } from "../hooks/useAthena";
import QueryEditor from "./QueryEditor";
import QueryResults from "./QueryResults";
import "./QueryPanel.scss";
import { useAwsToken } from "../hooks/useAwsToken";
import SchemaTree from "./SchemaTree";
import { useGlue } from "../hooks/useGlue";
import { useCodeMirror } from "../hooks/useCodeMirror";

interface QueryPanelProps {
  projectCode: string;
}

export type QueryEditorState = {
  query: string
}

export default function QueryPanel(props: QueryPanelProps) {
  const athena = useAthena(props.projectCode);
  const { awsAuthState } = useAwsToken();
  const { schema, schemaLoadingStatus } = useGlue(props.projectCode);
  const { setViewUpdate, setNameAtCursor } = useCodeMirror();

  if (awsAuthState === "error" || schemaLoadingStatus === "error") {
    return <Callout intent={Intent.DANGER}>Export Explorer is not currently available for this project. Please note that it will not be available until after the first export.</Callout>;
  }

  if (schemaLoadingStatus === "initial" || schemaLoadingStatus === "loading") {
    return <Callout>Loading...</Callout>;
  }

  return (
    <div className="query-panel-container">
      <div className="query-panel-top">
        <div className="query-panel-editor">
          <QueryEditor
            startQuery={athena.startQuery}
            projectCode={props.projectCode}
            schema={schema}
            onEditorUpdate={setViewUpdate}
          />
        </div>
        <div className="query-panel-schema">
          <SchemaTree
            projectCode={props.projectCode}
            schema={schema}
            onNodeClick={setNameAtCursor}
          />
        </div>
      </div>
      <Divider />
      <div className="query-panel-results">
        <QueryResults queryResults={athena.queryResults}></QueryResults>
      </div>
    </div>
  );
}
