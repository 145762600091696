import {
    AthenaClient,
    GetQueryExecutionCommand,
    GetQueryResultsCommand,
    QueryExecutionState,
    QueryExecutionStatus,
    StartQueryExecutionCommand,
    StartQueryExecutionCommandOutput
} from "@aws-sdk/client-athena";
import { useEffect, useState } from "react";
import { AwsToken } from "../contexts/awsTokenContext";
import { normalizeProjectCode } from "../helpers";
import { useAwsToken } from "./useAwsToken";

export interface UseAthena {
    startQuery: (query: string) => Promise<any>;
    queryResults?: AthenaQueryResults;
}

export interface AthenaQueryResults {
    queryExecutionId?: string;
    status?: QueryExecutionStatus;
    data?: AthenaResultData;
    projectCode?: string;
}

export interface AthenaResultData {
    headers: AthenaResultDataHeader[],
    rows: AthenaResultDataRow[]
}

export interface AthenaResultDataHeader {
    label: string;
    name: string;
}

export interface AthenaResultDataRow {
    values: string[];
}

const createClient = (awsToken: AwsToken) => {
    return new AthenaClient({
        region: process.env.REACT_APP_AWS_REGION!,
        credentials: {
            accessKeyId: awsToken.AccessKeyId,
            secretAccessKey: awsToken.SecretAccessKey,
            sessionToken: awsToken.SessionToken,
            expiration: new Date(awsToken.Expiration)
        }
    });
}

export const useAthena = (projectCode: string): UseAthena => {

    const { awsAuthState, awsToken } = useAwsToken();
    const [queryResults, setQueryResults] = useState<AthenaQueryResults | undefined>(undefined);
    const [athenaClient, setAthenaClient] = useState<AthenaClient | undefined>(undefined);

    useEffect(() => {
        if (awsAuthState === "success" && awsToken) {
            setAthenaClient(createClient(awsToken));
        }
    }, [awsAuthState, awsToken]);

    const startQuery = async (query: string): Promise<void> => {
        if (!athenaClient) {
            throw new Error("Athena client is not initialized");
        }
        const { s3Name, databaseName } = normalizeProjectCode(projectCode);
        try {
            let startQueryResult = await athenaClient.send(new StartQueryExecutionCommand({
                QueryString: query,
                QueryExecutionContext: {
                    Database: `${process.env.REACT_APP_DATABASE_PREFIX}_${databaseName}_${process.env.REACT_APP_DATABASE_SUFFIX}`
                },
                ResultConfiguration: {
                    OutputLocation: `${process.env.REACT_APP_S3_PREFIX}/${s3Name}`
                },
                WorkGroup: `${process.env.REACT_APP_ATHENA_WORKGROUP}`
            }));
            setQueryResults({
                queryExecutionId: startQueryResult.QueryExecutionId,
                status: undefined,
                projectCode: projectCode
            });
            pollForResults(startQueryResult.QueryExecutionId!)
        } catch (e) {
            let errorMessage = "Uknown error";
            if (e instanceof Error) {
                errorMessage = e.message;
            }
            setQueryResults({
                queryExecutionId: undefined,
                status: {
                    State: "FAILED",
                    SubmissionDateTime: new Date(),
                    AthenaError: {
                        ErrorMessage: errorMessage
                    }
                },
                projectCode: projectCode
            });
        }
    }

    const pollForResults = async (queryExecutionId: string) => {
        if (!athenaClient) {
            throw new Error("Athena client is not initialized");
        }

        let results = await athenaClient.send(new GetQueryExecutionCommand({
            QueryExecutionId: queryExecutionId
        }));

        const queryExecution = results.QueryExecution;
        let status = queryExecution?.Status;
        setQueryResults({
            queryExecutionId: queryExecutionId,
            status: status,
            projectCode: projectCode
        })

        if (status?.State === QueryExecutionState.SUCCEEDED) {
            const results = await getResults(queryExecutionId);
            let headers: AthenaResultDataHeader[] = [];
            const columnInfo = results?.ResultSet?.ResultSetMetadata?.ColumnInfo;
            if (columnInfo) {
                headers = columnInfo.map(c => ({ label: c.Label || "", name: c.Name || "" }));
            }
            let rows: AthenaResultDataRow[] = [];
            const resultRows = results.ResultSet?.Rows?.slice(1);
            if (resultRows) {
                rows = resultRows.map(r => ({ values: !r.Data ? [] : r.Data.map(d => d.VarCharValue!) }));
            }
            setQueryResults({
                queryExecutionId: queryExecutionId,
                status: status,
                data: {
                    headers: headers,
                    rows: rows
                },
                projectCode: projectCode
            })

        }

        if (!status?.State || status?.State === QueryExecutionState.RUNNING || status?.State === QueryExecutionState.QUEUED) {
            await sleep(2000);
            pollForResults(queryExecutionId);
        }
    }

    const getResults = async (queryExecutionId: string) => {
        if (!athenaClient) {
            throw new Error("Athena client is not initialized");
        }

        let results = await athenaClient.send(new GetQueryResultsCommand({
            QueryExecutionId: queryExecutionId,
            MaxResults: 100
        }));
        return results;
    }

    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    return {
        startQuery,
        queryResults: queryResults
    }

}