import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useEffect, useState } from "react";
import { AwsToken } from "../contexts/awsTokenContext";
import { useAwsToken } from "./useAwsToken";
import { normalizeProjectCode } from "../helpers";

const createClient = (awsToken: AwsToken) => {
	return new S3Client({
		region: process.env.REACT_APP_AWS_REGION!,
		credentials: {
			accessKeyId: awsToken.AccessKeyId,
			secretAccessKey: awsToken.SecretAccessKey,
			sessionToken: awsToken.SessionToken,
			expiration: new Date(awsToken.Expiration)
		}
	});
}

const getExecutionResultCommand = (projectCode: string, queryExecutionId: string | undefined): GetObjectCommand => {
	const cleanedProjectCode = normalizeProjectCode(projectCode);
	const splitUri = process.env.REACT_APP_S3_PREFIX?.split("/") as string[];
	const key = `${splitUri[3]}/${cleanedProjectCode.s3Name}/${queryExecutionId}.csv`;

	return new GetObjectCommand({
		Bucket: splitUri[2],
		Key: key,
	});
}

export const useS3 = (projectCode: string, queryExecutionId: string | undefined): string => {
	const { awsAuthState, awsToken } = useAwsToken();
	const [s3Client, setS3Client] = useState<S3Client | undefined>(undefined);

	useEffect(() => {
		if (awsAuthState === "success" && awsToken) {
			setS3Client(createClient(awsToken));
		}
	}, [awsAuthState, awsToken]);

	const [signedUrl, setSignedUrl] = useState<string>("");

	useEffect(() => {
		if (s3Client && queryExecutionId) {
			const command = getExecutionResultCommand(projectCode, queryExecutionId);
			const fetchSignedUrl = async () => {
				setSignedUrl(await getSignedUrl(s3Client, command));
			}
			fetchSignedUrl().catch(console.error);
		}
	}, [signedUrl, setSignedUrl, s3Client, projectCode, queryExecutionId]);

	if (!queryExecutionId) {
		return "";
	}

	return signedUrl;
}
