import type { User } from "oidc-client-ts";

export interface AuthState {
    user?: User | null;
    isAuthenticated: boolean;
    isInitialized: boolean;
    error?: Error;
}

export const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialized: false
};