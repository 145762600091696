import React, { useEffect, useMemo, useState } from "react";
import { UserManager, UserManagerSettings, User, SigninRedirectArgs } from "oidc-client-ts";

import { AuthContext } from "../contexts/authContext";
import { initialAuthState } from "../authentication/authState";
import { hasAuthParams, loginError } from "../authentication/utils";

export interface AuthProviderProps extends UserManagerSettings {
    children?: React.ReactNode;
}
export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
    const {
        children,
        ...userManagerSettings
    } = props;

    const [userManager] = useState(() => new UserManager(userManagerSettings));
    const [authState, updateAuthState] = useState(initialAuthState);

    const userManagerContext = useMemo(
        () => Object.assign(
            {
                settings: userManager.settings,
                events: userManager.events,
                signinRedirect: (args?: SigninRedirectArgs) => userManager.signinRedirect(args)
            }
        ),
        [userManager],
    );

    useEffect(() => {
        if (!userManager) return;
        void (async (): Promise<void> => {
            try {
                // check if returning back from authority server
                if (hasAuthParams()) {
                    await userManager.signinCallback();
                }
                const user = await userManager.getUser();
                updateAuthState({ user, isAuthenticated: user ? !user.expired : false, error: undefined, isInitialized: true });
            } catch (error) {
                updateAuthState({ user: undefined, isAuthenticated: false, error: loginError(error), isInitialized: true });
            }
        })();
    }, [userManager]);

    // register to userManager events
    useEffect(() => {
        if (!userManager) return undefined;
        // event UserLoaded (e.g. initial load, silent renew success)
        const handleUserLoaded = (user: User) => {
            updateAuthState({ user, isAuthenticated: user ? !user.expired : false, error: undefined, isInitialized: true });
        };
        userManager.events.addUserLoaded(handleUserLoaded);

        // event SilentRenewError (silent renew error)
        const handleSilentRenewError = (error: Error) => {
            updateAuthState({ user: undefined, isAuthenticated: false, error: loginError(error), isInitialized: true });
        };
        userManager.events.addSilentRenewError(handleSilentRenewError);

        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
            userManager.events.removeSilentRenewError(handleSilentRenewError);

        };
    }, [userManager]);

    useEffect(() => {
        if (authState.isAuthenticated) {
            userManager.startSilentRenew();
        } else {
            userManager.stopSilentRenew();
        }
    }, [authState.isAuthenticated]);

    return (
        <AuthContext.Provider
            value={{
                ...authState,
                ...userManagerContext
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};